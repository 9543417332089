const ENDERECO_DEFAULT_DATA = {
  "id": 1,
  "endereco": "Rua Primavera",
  "numero": "1207",
  "complemento": "Cx. Postal 201",
  "bairro": "Centro",
  "cidade": "Holambra",
  "estado": "SP",
  "cep": "13825-000",
  "emailContato": "administrativo@ancr.org.br",
  "telefone": "(19) 99199-9279",
  "fax": ""
};

const ESTADOS_DEFAULT_DATA = {
  "@context": "/contexts/Estado",
  "@id": "/estados",
  "@type": "hydra:Collection",
  "hydra:member": [
    {
      "@id": "/estados/AC",
      "@type": "Estado",
      "uf": "AC",
      "nome": "Acre"
    },
    {
      "@id": "/estados/AL",
      "@type": "Estado",
      "uf": "AL",
      "nome": "Alagoas"
    },
    {
      "@id": "/estados/AM",
      "@type": "Estado",
      "uf": "AM",
      "nome": "Amazonas"
    },
    {
      "@id": "/estados/AP",
      "@type": "Estado",
      "uf": "AP",
      "nome": "Amapá"
    },
    {
      "@id": "/estados/BA",
      "@type": "Estado",
      "uf": "BA",
      "nome": "Bahia"
    },
    {
      "@id": "/estados/CE",
      "@type": "Estado",
      "uf": "CE",
      "nome": "Ceará"
    },
    {
      "@id": "/estados/DF",
      "@type": "Estado",
      "uf": "DF",
      "nome": "Distrito Federal"
    },
    {
      "@id": "/estados/ES",
      "@type": "Estado",
      "uf": "ES",
      "nome": "Espírito Santo"
    },
    {
      "@id": "/estados/GO",
      "@type": "Estado",
      "uf": "GO",
      "nome": "Goiás"
    },
    {
      "@id": "/estados/MA",
      "@type": "Estado",
      "uf": "MA",
      "nome": "Maranhão"
    },
    {
      "@id": "/estados/MG",
      "@type": "Estado",
      "uf": "MG",
      "nome": "Minas Gerais"
    },
    {
      "@id": "/estados/MS",
      "@type": "Estado",
      "uf": "MS",
      "nome": "Mato Grosso do Sul"
    },
    {
      "@id": "/estados/MT",
      "@type": "Estado",
      "uf": "MT",
      "nome": "Mato Grosso"
    },
    {
      "@id": "/estados/PA",
      "@type": "Estado",
      "uf": "PA",
      "nome": "Pará"
    },
    {
      "@id": "/estados/PB",
      "@type": "Estado",
      "uf": "PB",
      "nome": "Paraíba"
    },
    {
      "@id": "/estados/PE",
      "@type": "Estado",
      "uf": "PE",
      "nome": "Pernambuco"
    },
    {
      "@id": "/estados/PI",
      "@type": "Estado",
      "uf": "PI",
      "nome": "Piauí"
    },
    {
      "@id": "/estados/PR",
      "@type": "Estado",
      "uf": "PR",
      "nome": "Paraná"
    },
    {
      "@id": "/estados/RJ",
      "@type": "Estado",
      "uf": "RJ",
      "nome": "Rio de Janeiro"
    },
    {
      "@id": "/estados/RN",
      "@type": "Estado",
      "uf": "RN",
      "nome": "Rio Grande do Norte"
    },
    {
      "@id": "/estados/RO",
      "@type": "Estado",
      "uf": "RO",
      "nome": "Rondônia"
    },
    {
      "@id": "/estados/RR",
      "@type": "Estado",
      "uf": "RR",
      "nome": "Roraima "
    },
    {
      "@id": "/estados/RS",
      "@type": "Estado",
      "uf": "RS",
      "nome": "Rio Grande do Sul"
    },
    {
      "@id": "/estados/SC",
      "@type": "Estado",
      "uf": "SC",
      "nome": "Santa Catarina"
    },
    {
      "@id": "/estados/SE",
      "@type": "Estado",
      "uf": "SE",
      "nome": "Sergipe"
    },
    {
      "@id": "/estados/SP",
      "@type": "Estado",
      "uf": "SP",
      "nome": "São Paulo"
    },
    {
      "@id": "/estados/TO",
      "@type": "Estado",
      "uf": "TO",
      "nome": "Tocantins"
    }
  ],
  "hydra:totalItems": 27
};

const RACAS_DEFAULT_DATA = {
  "@context": "/contexts/racas",
  "@id": "/racas",
  "@type": "hydra:Collection",
  "hydra:member": [
    {
      "@id": "/racas/1",
      "@type": "racas",
      "id": 1,
      "descricao": "Quarto de Milha"
    },
    {
      "@id": "/racas/2",
      "@type": "racas",
      "id": 2,
      "descricao": "Crioulo"
    },
    {
      "@id": "/racas/3",
      "@type": "racas",
      "id": 3,
      "descricao": "Paint"
    },
    {
      "@id": "/racas/4",
      "@type": "racas",
      "id": 4,
      "descricao": "Appaloosa"
    },
    {
      "@id": "/racas/5",
      "@type": "racas",
      "id": 5,
      "descricao": "Mula"
    },
    {
      "@id": "/racas/6",
      "@type": "racas",
      "id": 6,
      "descricao": "Outro"
    }
  ],
  "hydra:totalItems": 6
};

const FORMAS_DE_PAGAMENTO_DEFAULT_DATA = {
  "@context": "/contexts/formas_pagamento",
  "@id": "/formas_pagamentos",
  "@type": "hydra:Collection",
  "hydra:member": [
    {
      "@id": "/formas_pagamentos/1",
      "@type": "formas_pagamento",
      "id": 1,
      "descricao": "Boleto",
      "cartaoDeCredito": false,
      "pix": false,
      "ativo": true
    },
    {
      "@id": "/formas_pagamentos/2",
      "@type": "formas_pagamento",
      "id": 2,
      "descricao": "Cheque",
      "cartaoDeCredito": false,
      "pix": false,
      "ativo": false
    },
    {
      "@id": "/formas_pagamentos/3",
      "@type": "formas_pagamento",
      "id": 3,
      "descricao": "Cartão de Crédito",
      "cartaoDeCredito": true,
      "pix": false,
      "ativo": false
    },
    {
      "@id": "/formas_pagamentos/4",
      "@type": "formas_pagamento",
      "id": 4,
      "descricao": "Pix",
      "cartaoDeCredito": false,
      "pix": true,
      "ativo": false
    }
  ],
  "hydra:totalItems": 3
};

const SEXOS_ANIMAIS_DEFAULT_DATA = {
  "@context": "/contexts/sexos_animais",
  "@id": "/sexos_animais",
  "@type": "hydra:Collection",
  "hydra:member": [
    {
      "@id": "/sexos_animais/0",
      "@type": "sexos_animais",
      "id": 0,
      "descricao": "Fêmea",
      "macho": false,
      "castrado": false,
      "descricaoIngles": "Mare"
    },
    {
      "@id": "/sexos_animais/1",
      "@type": "sexos_animais",
      "id": 1,
      "descricao": "Macho",
      "macho": true,
      "castrado": false,
      "descricaoIngles": "Stallion"
    },
    {
      "@id": "/sexos_animais/2",
      "@type": "sexos_animais",
      "id": 2,
      "descricao": "Castrado",
      "macho": true,
      "castrado": true,
      "descricaoIngles": "Gelding"
    }
  ],
  "hydra:totalItems": 3
};

const FOOTER_MENU_DEFAULT_DATA = [{
  fila: 1,
  header: true,
  headerRow: 1,
  footer: false,
  titulo: 'Início',
  url: '/',
  itens: [],
}, {
  fila: 1,
  header: true,
  headerRow: 1,
  footer: false,
  titulo: 'Notícias',
  url: '/noticias',
  itens: [],
}, {
  fila: 1,
  header: true,
  headerRow: 1,
  footer: false,
  titulo: 'Animais',
  url: '/campanhas?animais=1',
  itens: [],
}, {
  fila: 1,
  header: true,
  headerRow: 1,
  footer: false,
  titulo: 'Competidores',
  url: '/campanhas?animais=0',
  itens: [],
}, {
  fila: 1,
  header: false,
  headerRow: null,
  footer: true,
  titulo: 'Principal',
  itens: [{
    url: '/',
    title: 'Início',
  }, {
    url: '/noticias',
    title: 'Notícias',
  }, {
    url: '/campanhas?animais=1',
    title: 'Animais',
  }, {
    url: '/campanhas?animais=0',
    title: 'Competidores',
  }, {
    url: '/eventos?futuros=true&resultados=false&order=asc',
    title: 'Eventos',
  }],
}, {
  fila: 2,
  header: true,
  headerRow: 2,
  footer: true,
  titulo: 'Eventos',
  itens: [{
    url: '/eventos?futuros=true&resultados=false&order=asc',
    title: 'Calendário',
  }, {
    url: '/eventos?futuros=false&resultados=true&order=desc',
    title: 'Resultados',
  }, {
    url: '/eventos?fotos=true&order=desc',
    title: 'Fotos',
  }, {
    url: '/eventos?videos=true&order=desc',
    title: 'Vídeos',
  }, {
    url: '/regulamentonacional',
    title: 'Circular Nacional',
    static: true,
    id: "F7A2A4B6-804A-41E2-97BD-C5742C9A874D",
    page: 1,
  }, {
    url: '/classificadosnacional',
    title: 'Classificados Nacional',
    static: true,
    id: "B007B3D3-0E60-4275-B9A1-5377F8CE1DB7",
    page: 1,
  }],
}, {
  fila: 2,
  header: true,
  headerRow: 2,
  footer: true,
  titulo: 'Nominação',
  itens: [{
    url: '/normasnominacao',
    title: 'Normas',
    static: true,
    id: "1777405F-BCAB-41D7-8070-7185E616B429",
    page: 1,
  }, {
    url: '/formularionominacao',
    title: 'Formulário'
  }, {
    url: '/nominacoes',
    title: 'Relação',

  }],
}, {
  fila: 3,
  header: false,
  headerRow: 2,
  footer: true,
  titulo: 'Níveis',
  itens: [{
    url: '/regulamentoniveis',
    title: 'Critérios',
    static: true,
    id: "8E19929D-3515-44C2-AAD0-2BD13F93A99B",
    page: 1,
  }, {
    url: '/niveis?aberta=1',
    title: 'Aberta'
  }, {
    url: '/niveis?aberta=0',
    title: 'Amador'
  }],
}, {
  fila: 3,
  header: false,
  headerRow: 2,
  footer: true,
  titulo: 'Serviços',
  itens: [{
    url: '/juizes',
    title: 'Juízes',
  }, {
    url: '/treinadores',
    title: 'Treinadores',
  }],
}, {
  fila: 4,
  header: true,
  headerRow: 2,
  footer: true,
  titulo: 'Ranking',
  itens: [{
    url: '/ranking',
    title: 'Temporada',
  }, {
    url: '/rankingacumulado',
    title: 'Acumulado',
  }],
}, {
  fila: 4,
  header: true,
  headerRow: 2,
  footer: true,
  titulo: 'Afiliados',
  itens: [{
    url: '/nucleos',
    title: 'Núcleos',
  }, {
    url: '/eventos?resultados=true&futuros=false&ancr=false&nucleos=true&order=desc',
    title: 'Resultados',
  }],
}, {
  fila: 5,
  header: false,
  headerRow: 2,
  footer: true,
  titulo: 'Institucional',
  itens: [{
    url: '/cnpj',
    title: 'CNPJ',
    static: true,
    id: "713F55DD-2649-4101-BC7F-E12A914A6F1F",
    page: 1,
  }, {
    url: '/estatuto',
    title: 'Estatuto',
    static: true,
    id: "2682429D-09F4-45A5-B37D-933655772552",
    page: 1,
  }, {
    url: '/historico',
    title: 'Histórico',
    static: true,
    id: "B9647249-D577-42DB-8A04-70E0153417D6",
    page: 1,
  }, {
    url: '/regulamento',
    title: 'Regulamento',
    // static: true,
    // id: "FD67813B-2F67-42C2-B5B5-5292DF3EAC6E",
    // page: 1,
  }, {
    url: '/percursos',
    title: 'Percursos',
  }, {
    url: '/diretoria',
    title: 'Diretoria',
    static: true,
    id: "816829AB-49E9-4CAD-B317-B37E96100166",
    page: 1,
  }, {
    url: '/afiliar',
    title: 'Associe-se'
  }],
}, {
  fila: 5,
  header: false,
  headerRow: 2,
  footer: true,
  titulo: 'Contato',
  itens: [{
    url: '/contato',
    title: 'Contate-nos'
  }],
}];

const HEADER_MENU_DEFAULT_DATA = [{
  titulo: 'Início',
  url: '/',
  // }, {
  //   titulo: 'Sobre',
  //   url: '/sobre',
  //   static: true,
  //   id: "B9647249-D577-42DB-8A04-70E0153417D6",
  //   page: 1,
}, {
  titulo: 'Notícias',
  url: '/noticias',
}, {
  titulo: 'Eventos',
  url: '/eventos?futuros=true&resultados=false&order=asc',
}, {
  titulo: 'Resultados',
  url: '/eventos?resultados=true&futuros=false&order=desc',
}, {
  titulo: 'Ranking',
  url: '/ranking',
}, {
  titulo: 'Campanhas',
  url: '/campanhas?animais=1',
}, {
  titulo: 'Percursos',
  url: '/percursos',
}, {
  url: '/nucleos',
  titulo: 'Núcleos',
}, {
  url: '/nominacoes',
  titulo: 'Nominações',
}, {
  url: '/afiliar',
  titulo: "Afilie\u2011se", // with non-breaking hyphen
}, {
  url: '/halldafama',
  titulo: "Hall\u00A0da\u00A0Fama", // with non-breaking space
}, {
  url: '/contato',
  titulo: 'Contato',
}];

const RANKING_SEASONS_DEFAULT_DATA = {
  "@context": "/contexts/Evento",
  "@id": "/eventos",
  "@type": "hydra:Collection",
  "hydra:member": [
    {
      "temporada": 2021
    },
    {
      "temporada": 2020
    },
    {
      "temporada": 2019
    },
    {
      "temporada": 2018
    },
    {
      "temporada": 2017
    },
    {
      "temporada": 2016
    },
    {
      "temporada": 2015
    },
    {
      "temporada": 2014
    },
    {
      "temporada": 2013
    },
    {
      "temporada": 2012
    },
    {
      "temporada": 2011
    },
    {
      "temporada": 2010
    },
    {
      "temporada": 2009
    }
  ],
  "hydra:totalItems": 12,
  "hydra:search": {
    "@type": "hydra:IriTemplate",
    "hydra:template": "/eventos/temporadas_ranking{?provaAncr,fim,emAndamento,dataIni[between],dataIni[gt],dataIni[gte],dataIni[lt],dataIni[lte],dataFim[between],dataFim[gt],dataFim[gte],dataFim[lt],dataFim[lte],search,campeonato.id,campeonato.id[],order[dataIni]}",
    "hydra:variableRepresentation": "BasicRepresentation",
    "hydra:mapping": [
      {
        "@type": "IriTemplateMapping",
        "variable": "provaAncr",
        "property": "provaAncr",
        "required": false
      },
      {
        "@type": "IriTemplateMapping",
        "variable": "fim",
        "property": "fim",
        "required": false
      },
      {
        "@type": "IriTemplateMapping",
        "variable": "emAndamento",
        "property": "emAndamento",
        "required": false
      },
      {
        "@type": "IriTemplateMapping",
        "variable": "dataIni[between]",
        "property": "dataIni",
        "required": false
      },
      {
        "@type": "IriTemplateMapping",
        "variable": "dataIni[gt]",
        "property": "dataIni",
        "required": false
      },
      {
        "@type": "IriTemplateMapping",
        "variable": "dataIni[gte]",
        "property": "dataIni",
        "required": false
      },
      {
        "@type": "IriTemplateMapping",
        "variable": "dataIni[lt]",
        "property": "dataIni",
        "required": false
      },
      {
        "@type": "IriTemplateMapping",
        "variable": "dataIni[lte]",
        "property": "dataIni",
        "required": false
      },
      {
        "@type": "IriTemplateMapping",
        "variable": "dataFim[between]",
        "property": "dataFim",
        "required": false
      },
      {
        "@type": "IriTemplateMapping",
        "variable": "dataFim[gt]",
        "property": "dataFim",
        "required": false
      },
      {
        "@type": "IriTemplateMapping",
        "variable": "dataFim[gte]",
        "property": "dataFim",
        "required": false
      },
      {
        "@type": "IriTemplateMapping",
        "variable": "dataFim[lt]",
        "property": "dataFim",
        "required": false
      },
      {
        "@type": "IriTemplateMapping",
        "variable": "dataFim[lte]",
        "property": "dataFim",
        "required": false
      },
      {
        "@type": "IriTemplateMapping",
        "variable": "search",
        "property": "search",
        "required": false
      },
      {
        "@type": "IriTemplateMapping",
        "variable": "campeonato.id",
        "property": "campeonato.id",
        "required": false
      },
      {
        "@type": "IriTemplateMapping",
        "variable": "campeonato.id[]",
        "property": "campeonato.id",
        "required": false
      },
      {
        "@type": "IriTemplateMapping",
        "variable": "order[dataIni]",
        "property": "dataIni",
        "required": false
      }
    ]
  }
};

const DESTAQUES_DEFAULT_DATA = [{
  id: "39FBD39B-0908-41CF-8D83-F568B3653EF5",
  tipo: 'img',
  titulo: "",
  src: "images/ANCR-logo-gray-16x9.png",
}];

const COUNTRIES_DEFAULT_DATA = {
  "@context": "/contexts/Paises",
  "@id": "/paises",
  "@type": "hydra:Collection",
  "hydra:member": [
    {
      "@id": "/paises/1",
      "@type": "Paises",
      "id": 1,
      "nome": "Brasil",
      "codigoAlfa2": "BR",
      "codigoAlfa3": "BRA"
    },
    {
      "@id": "/paises/2",
      "@type": "Paises",
      "id": 2,
      "nome": "Uruguai",
      "codigoAlfa2": "UY",
      "codigoAlfa3": "URY"
    },
    {
      "@id": "/paises/3",
      "@type": "Paises",
      "id": 3,
      "nome": "Argentina",
      "codigoAlfa2": "AR",
      "codigoAlfa3": "ARG"
    },
    {
      "@id": "/paises/4",
      "@type": "Paises",
      "id": 4,
      "nome": "Paraguai",
      "codigoAlfa2": "PY",
      "codigoAlfa3": "PRY"
    },
    {
      "@id": "/paises/5",
      "@type": "Paises",
      "id": 5,
      "nome": "Chile",
      "codigoAlfa2": "CL",
      "codigoAlfa3": "CHL"
    },
    {
      "@id": "/paises/6",
      "@type": "Paises",
      "id": 6,
      "nome": "EUA",
      "codigoAlfa2": "US",
      "codigoAlfa3": "USA"
    }
  ],
  "hydra:totalItems": 6
};

const COMPETITOR_CLASSES_DEFAULT_DATA = {
  "@context": "/contexts/CategoriasCompetidores",
  "@id": "/categorias_competidores",
  "@type": "hydra:Collection",
  "hydra:member": [
    {
      "@id": "/categorias_competidores/0",
      "@type": "CategoriasCompetidores",
      "id": 0,
      "descricao": "Não",
      "aberta": false,
      "amador": false
    },
    {
      "@id": "/categorias_competidores/1",
      "@type": "CategoriasCompetidores",
      "id": 1,
      "descricao": "Aberta/Treinador",
      "aberta": true,
      "amador": false
    },
    {
      "@id": "/categorias_competidores/2",
      "@type": "CategoriasCompetidores",
      "id": 2,
      "descricao": "Amador",
      "aberta": false,
      "amador": true
    },
    {
      "@id": "/categorias_competidores/3",
      "@type": "CategoriasCompetidores",
      "id": 3,
      "descricao": "Principiante",
      "aberta": false,
      "amador": true
    }
  ],
  "hydra:totalItems": 4
};

const AFFILIATE_LIST_DEFAULT_DATA = {
  "@context": "/contexts/Campeonato",
  "@id": "/campeonatos",
  "@type": "hydra:Collection",
  "hydra:member": [
    {
      "@id": "/campeonatos/1",
      "@type": "Campeonato",
      "id": 1,
      "descricao": "Catarinense",
      "lugar": "Santa Catarina",
      "nucleo": "ACCR - Associação Catarinense do Cavalo de Rédeas"
    },
    {
      "@id": "/campeonatos/2",
      "@type": "Campeonato",
      "id": 2,
      "descricao": "Rio Grandense",
      "lugar": "Rio Grande do Sul",
      "nucleo": "ARCR - Associação Rio Grandense do Cavalo de Rédeas"
    },
    {
      "@id": "/campeonatos/3",
      "@type": "Campeonato",
      "id": 3,
      "descricao": "Mineiro",
      "lugar": "Minas Gerais",
      "nucleo": "AMCR - Associação Mineira do Cavalo de Rédeas"
    },
    {
      "@id": "/campeonatos/4",
      "@type": "Campeonato",
      "id": 4,
      "descricao": "Paranaense",
      "lugar": "Paraná",
      "nucleo": "APCR - Associação Paranaense do Cavalo de Rédeas"
    },
    {
      "@id": "/campeonatos/5",
      "@type": "Campeonato",
      "id": 5,
      "descricao": "Brasilense",
      "lugar": "Brasília",
      "nucleo": "ABCR - Associação Brasiliense do Cavalo de Rédeas"
    },
    {
      "@id": "/campeonatos/6",
      "@type": "Campeonato",
      "id": 6,
      "descricao": "Rondonense",
      "lugar": "Rondônia",
      "nucleo": "ARCR - Associação Rondoniense do Cavalo de Rédeas"
    },
    {
      "@id": "/campeonatos/7",
      "@type": "Campeonato",
      "id": 7,
      "descricao": "Anhanguera",
      "lugar": "Anhangüera",
      "nucleo": "NACR - Núcleo Anhanguera do Cavalo de Rédeas"
    },
    {
      "@id": "/campeonatos/8",
      "@type": "Campeonato",
      "id": 8,
      "descricao": "Castelo",
      "lugar": "Castelo Branco",
      "nucleo": "NCCR - Núcleo Castelo Branco do Cavalo de Rédeas"
    },
    {
      "@id": "/campeonatos/13",
      "@type": "Campeonato",
      "id": 13,
      "descricao": "Carióca",
      "lugar": "Rio de Janeiro",
      "nucleo": "ACCRP - Associação Carioca do Cavalo de Rédeas e Pleasure"
    },
    {
      "@id": "/campeonatos/14",
      "@type": "Campeonato",
      "id": 14,
      "descricao": "Centro Oeste",
      "lugar": "Centro Oeste",
      "nucleo": "ACOR - Associação Centro Oeste de Rédeas"
    },
    {
      "@id": "/campeonatos/16",
      "@type": "Campeonato",
      "id": 16,
      "descricao": "Uruguaio",
      "lugar": "Uruguay",
      "nucleo": "ANCRU - Asociación Nacional de Caballos de Rienda Uruguay"
    },
    {
      "@id": "/campeonatos/18",
      "@type": "Campeonato",
      "id": 18,
      "descricao": "Triângulo Mineiro",
      "lugar": "Triângulo Mineiro",
      "nucleo": "ATMCR - Associação Triângulo Mineiro do Cavalo de Rédeas"
    }
  ],
  "hydra:totalItems": 12,
  "hydra:view": {
    "@id": "/campeonatos?itemsPerPage=30",
    "@type": "hydra:PartialCollectionView"
  }
};

const OPCOES_PAGAMENTO_DEFAULT_DATA = {
  "@context": "/contexts/OpcoesPagamento",
  "@id": "/opcoes_pagamentos",
  "@type": "hydra:Collection",
  "hydra:member": [
    {
      "@id": "/opcoes_pagamentos/1",
      "@type": "OpcoesPagamento",
      "id": 1,
      "numeroParcelas": 1,
      "descricao": "À vista"
    },
    {
      "@id": "/opcoes_pagamentos/2",
      "@type": "OpcoesPagamento",
      "id": 2,
      "numeroParcelas": 2,
      "descricao": "2 parcelas"
    },
    {
      "@id": "/opcoes_pagamentos/3",
      "@type": "OpcoesPagamento",
      "id": 3,
      "numeroParcelas": 3,
      "descricao": "3 parcelas"
    },
    {
      "@id": "/opcoes_pagamentos/4",
      "@type": "OpcoesPagamento",
      "id": 4,
      "numeroParcelas": 4,
      "descricao": "4 parcelas"
    }
  ],
  "hydra:totalItems": 4
};

const CAPAS_REVISTAS_DEFAULT_DATA = [
  {
    edicao: 1,
    imagemUrl: 'https://images.ancr.org.br/2022_revistaredeas_edicao01-708x945.jpg',
    url: null,
  },
  {
    edicao: 4,
    imagemUrl: 'https://images.ancr.org.br/2022_revistaredeas_edicao04-708x945.jpg',
    url: null,
  },
  {
    edicao: 6,
    imagemUrl: 'https://images.ancr.org.br/2022_revistaredeas_edicao06-708x945.jpg',
    url: null,
  },
  {
    edicao: 7,
    imagemUrl: 'https://images.ancr.org.br/2022_revistaredeas_edicao07-708x945.jpg',
    url: null,
  },
  {
    edicao: 8,
    imagemUrl: 'https://images.ancr.org.br/2022_revistaredeas_edicao08-708x945.jpg',
    url: null,
  },
  {
    edicao: 9,
    imagemUrl: 'https://images.ancr.org.br/2022_revistaredeas_edicao09-708x945.jpg',
    url: null,
  },
  {
    edicao: 11,
    imagemUrl: 'https://images.ancr.org.br/2022_revistaredeas_edicao11-708x945.jpg',
    url: null,
  },
  {
    edicao: 12,
    imagemUrl: 'https://images.ancr.org.br/2022_revistaredeas_edicao12-708x945.jpg',
    url: null,
  },
  {
    edicao: 13,
    imagemUrl: 'https://images.ancr.org.br/2022_revistaredeas_edicao13-708x945.jpg',
    url: null,
  },
  {
    edicao: 14,
    imagemUrl: 'https://images.ancr.org.br/2022_revistaredeas_edicao14-708x945.jpg',
    url: null,
  },
  {
    edicao: 15,
    imagemUrl: 'https://images.ancr.org.br/2022_revistaredeas_edicao15-708x945.jpg',
    url: null,
  },
  {
    edicao: 16,
    imagemUrl: 'https://images.ancr.org.br/2022_revistaredeas_edicao16-708x945.jpg',
    url: null,
  },
  {
    edicao: 17,
    imagemUrl: 'https://images.ancr.org.br/2022_revistaredeas_edicao17-708x945.jpg',
    url: null,
  },
  {
    edicao: 18,
    imagemUrl: 'https://images.ancr.org.br/2022_revistaredeas_edicao18-708x945.jpg',
    url: null,
  },
  {
    edicao: 19,
    imagemUrl: 'https://images.ancr.org.br/2022_revistaredeas_edicao19-708x945.jpg',
    url: null,
  },
];

const MUNDO_REDEAS_DEFAULT_DATA = [
  {
    titulo: 'Última edição',
    videoId: 'BIcv6C4p_Bk'
  },
  {
    titulo: '1ª edição',
    videoId: '5NysNJfbkHo'
  }
];

const LINKS_REGULAMENTO_DEFAULT_DATA = [
  {
    titulo: 'Livro de Regras da ANCR',
    imagemUrl: 'https://images.ancr.org.br/2022_ancr_regulamento_441x1000px.jpg',
    url: 'https://files.ancr.org.br/downloads/ANCR_REGULAMENTO_2022.pdf',
  },
  {
    titulo: 'Livro de Regras da NRHA',
    imagemUrl: 'https://images.ancr.org.br/2022_nrha_handbook.jpg',
    url: 'https://nrha.com/media/pdf/handbook/2022/22-handbook.pdf',
  },
  {
    titulo: 'Livro de Percursos da NRHA',
    imagemUrl: 'https://images.ancr.org.br/2017_nrha_patterns_01_441x1000px.jpg',
    url: 'https://nrha.com/media/pdf/handbook/2023/patterns.pdf',
  },
];

export {
  ENDERECO_DEFAULT_DATA,
  ESTADOS_DEFAULT_DATA,
  RACAS_DEFAULT_DATA,
  FORMAS_DE_PAGAMENTO_DEFAULT_DATA,
  SEXOS_ANIMAIS_DEFAULT_DATA,
  FOOTER_MENU_DEFAULT_DATA,
  HEADER_MENU_DEFAULT_DATA,
  RANKING_SEASONS_DEFAULT_DATA,
  DESTAQUES_DEFAULT_DATA,
  COUNTRIES_DEFAULT_DATA,
  COMPETITOR_CLASSES_DEFAULT_DATA,
  AFFILIATE_LIST_DEFAULT_DATA,
  OPCOES_PAGAMENTO_DEFAULT_DATA,
  CAPAS_REVISTAS_DEFAULT_DATA,
  MUNDO_REDEAS_DEFAULT_DATA,
  LINKS_REGULAMENTO_DEFAULT_DATA,
};